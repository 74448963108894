'use strict'

import ScrollMagic from 'scrollmagic';
import {TweenLite, TweenMax, TimelineMax} from 'gsap';
import animationGsap from '../lib/animation.gsap';
import debug from '../lib/debug.addIndicators';



let self

export default self = {
  container: document.querySelector('.main-template'),

  init : () => {
    const controller = new ScrollMagic.Controller();

    /*---------------------------------------*/
    /*         Main Wrapper Pinned           */
    /*---------------------------------------*/

    // const pinned = function(){
    //   // Quit less than 930px width
    //   if(window.innerWidth >= 930) {
    //     const wrapperScene = new ScrollMagic.Scene({
    //       triggerElement: '.main-template',
    //       triggerHook: 0,
    //       duration: '100%'
    //     })
    //     .setPin('.main-wrapper')
    //     .on('end', function(e){
    //       const body = document.querySelector('body');
    //       if(body.classList.contains('is-changed')){
    //         body.classList.remove('is-changed');            
    //       } else {
    //         body.classList.add('is-changed');            
    //       } 
    //     })
    //     // .addIndicators({name: 'wrapper'})
    //     .addTo(controller);
    //   }
    // }
    // pinned();
    // window.addEventListener("resize", pinned);


    /*---------------------------------------*/
    /*         Two Image Animation           */
    /*---------------------------------------*/
    // function twoImages() {
    //   const twoImageAnimation = new TimelineLite()
    //   .add('start')
    //   .to('.image-box--left', 1, {width: '25%', ease: Linear.easeNone}, 'start')
    //   .to('.image-box--right', 1, {width: '70%', ease: Linear.easeNone}, 'start');

    //   const twoImageScene = new ScrollMagic.Scene({
    //         triggerElement: '.two-images',
    //         triggerHook: 0.4,
    //         duration: '40%'
    //     })
    //     .setTween(twoImageAnimation)
    //     // .addIndicators({name: 'twoImage'})
    //     .addTo(controller);
    // }

    // const twoImagesContainer = document.querySelector('.two-images');
    // if(twoImagesContainer && window.innerWidth >= 930){
    //   twoImages();
    // }
    
    /*---------------------------------------*/
    /*         Fade In Animation             */
    /*---------------------------------------*/

    const oneColumns = document.querySelectorAll('.one-column');

    [...oneColumns].forEach((column)=>{
      const fadeInAnimation = new TimelineLite()
      .fromTo(column.querySelector('.inner-wrap--narrow'), 1, {autoAlpha: 0, y: 40}, {autoAlpha: 1, y: 0});

      const fadeInScene = new ScrollMagic.Scene({
            triggerElement: column,
            triggerHook: 0.8
        })
        .setTween(fadeInAnimation)
        // .addIndicators({name: 'twoImage'})
        .addTo(controller);
    });

    /*---------------------------------------*/
    /*        Pagination Animation           */
    /*---------------------------------------*/

    const pagination = document.querySelector('.pagination');
    
    const paginationAnimation = new TimelineLite()
      .set('.pagination p', {autoAlpha: 0, y: 30})
      .set('.pagination h1', {autoAlpha: 0, y: 30})
      .to('.pagination p', 1, {autoAlpha: 1, y: 0})
      .to('.pagination h1', 1, {autoAlpha: 1, y: 0}, '-=0.6');

    const paginationScene = new ScrollMagic.Scene({
          triggerElement: pagination,
          triggerHook: 0.65
      })
      .setTween(paginationAnimation)
      // .addIndicators({name: 'twoImage'})
      .addTo(controller);

  },
}