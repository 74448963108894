'use strict'

import Barba from 'barba.js'
import {TweenLite, TimelineLite} from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import ScrollAnimation from './scrollAnimation'
import ScrollToTop from './scrollToTop'
import Slideshow from './slideshow'
import Floorplan from './floorplan'
import Gallery from './gallery'
import IsAtBottom from './isAtBottom'


let self

export default self = {
  container : document.querySelector('.barba-container'),

  init : () => {
    Barba.Pjax.init();
    Barba.Prefetch.init();


    Barba.Dispatcher.on('transitionCompleted', function(currentStatus, oldStatus, container) {
      if (currentStatus.url.search('gallery') !== -1) Gallery.init()
      if (currentStatus.url.search('workspace') !== -1) IsAtBottom.init('workspace')
    });

    //---------------------------------------//
    //            Template Page              //
    //---------------------------------------//
    const Template = Barba.BaseView.extend({
        namespace: "template",
        ScrollAnimation: null,
        ScrollToTop: null,
        Slideshow: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.ScrollAnimation = null;
            this.ScrollToTop = null;
            this.Slideshow = null;
        },
        start: function() {
            this.ScrollAnimation = ScrollAnimation;
            this.ScrollAnimation.init();
            this.ScrollToTop = ScrollToTop;
            this.ScrollToTop.init();
            this.Slideshow = Slideshow;
            this.Slideshow.init();
        }
    });
    Template.init();

    //---------------------------------------//
    //        Creative Energy Page           //
    //---------------------------------------//
    const CreativeEnergy = Barba.BaseView.extend({
        namespace: "creative-energy",
        ScrollAnimation: null,
        ScrollToTop: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.ScrollAnimation = null;
            this.ScrollToTop = null;
        },
        start: function() {
            this.ScrollAnimation = ScrollAnimation;
            this.ScrollAnimation.init();
            this.ScrollToTop = ScrollToTop;
            this.ScrollToTop.init();
        }
    });
    CreativeEnergy.init();

    //---------------------------------------//
    //        Basic Template Page            //
    //---------------------------------------//
    const BasicTemplate = Barba.BaseView.extend({
      namespace: "basic-template",
      ScrollToTop: null,
      onEnter: function() {
          this._construct();
      },
      onEnterCompleted: function() {
          this.start();
      },
      _construct: function() {
          this.destroy();
      },
      destroy: function() {
          this.ScrollToTop = null;
      },
      start: function() {
          this.ScrollToTop = ScrollToTop;
          this.ScrollToTop.init();
      }
    });
    BasicTemplate.init();

    //---------------------------------------//
    //              Plan Page                //
    //---------------------------------------//
    const Plan = Barba.BaseView.extend({
      namespace: "plans",
      ScrollToTop: null,
      onEnter: function() {
          this._construct();
      },
      onEnterCompleted: function() {
          this.start();
      },
      _construct: function() {
          this.destroy();
      },
      destroy: function() {
          this.ScrollToTop = null;
          this.Floorplan = null;
      },
      start: function() {
          this.ScrollToTop = ScrollToTop;
          this.ScrollToTop.init();
          this.Floorplan = Floorplan;
          this.Floorplan.init();
      }
    });
    Plan.init();




    //---------------------------------------//
    //            Page Transition            //
    //---------------------------------------//
    const PageAnimation = Barba.BaseTransition.extend({
      start: function() {
        Promise
          .all([this.newContainerLoading])
          .then(this.fadeOut.bind(this));
      },

      fadeOut: function() {
        const _this = this;
        const cover = document.querySelector('.transition-cover');
        const body = document.querySelector('body');
        const tlFadeOut = new TimelineLite();

        return tlFadeOut
          .add('start')
          .set(body, {overflow: 'hidden'})
          .set(cover, {autoAlpha: 1}, 'start')
          .to(window, 1, {scrollTo:'main'}, 'start')
          .set(this.oldContainer, {display: 'none'})
          .set(this.newContainer, {autoAlpha: 1})
          .to('.rotate-icon', 1.5, {rotation: 360}, '-=0.8')
          .to(cover, 0.6, {autoAlpha: 0, onComplete: function() {
            TweenLite.set([_this.newContainer, cover, body, '.rotate-icon'], { clearProps: 'all' });
            _this.done();
          }}, '-=0.2');
      },
    });

    Barba.Pjax.getTransition = function() {
      return PageAnimation;
    };


  }
}
