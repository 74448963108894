'use strict'

import { TimelineLite } from 'gsap';
import Swiper from 'swiper'

let self;

export default self = {
    container: document.querySelector('.preload-bg'),
    coverText: document.querySelector('.preload-bg .bg-image'),
    coverWhiteFirst: document.querySelector('.preload-bg .bg-white--first'),
    coverWhiteSecond: document.querySelector('.preload-bg .bg-white--second'),
    manifesto: document.querySelector('.main-manifesto'),
    body: document.body,
    homegalleryContainer: document.querySelector('.video-box .swiper-container'),
    textBox : document.querySelector('.text-box'),

    init: () => {
        if (self.container) self.homePreload();
        // if (self.homegalleryContainer) self.gallerySlide();

    },
    homePreload: () => {

        const preloaderTl = new TimelineMax({onComplete: () => {
            //if (self.homegalleryContainer) self.gallerySlide();
        }});

        if (window.innerWidth > 1024) {
          preloaderTl
              .set(self.body, { overflow: 'hidden' })
              .set(self.coverWhiteFirst, { opacity: 0 })
              .to(self.coverWhiteFirst, 0, { delay: 0.3 })
              .to(self.coverWhiteFirst, 0.8, { delay: 1.4, autoAlpha: 1 })
              .set(self.body, { className: '+=is-loaded' })
              .set(self.body, { overflow: 'visible' })
              .set(self.container, { display: 'none' })
              .fromTo('#header', 0.6, { autoAlpha: 0, y: -20 }, { autoAlpha: 1, y: 0 })
              .to(self.coverWhiteFirst, 0.1, { autoAlpha: 0 })
              .add('homepagecontent')
              //.fromTo(self.manifesto, 1, { y: +20 }, { autoAlpha: 1, y: 0 }, 'homepagecontent')
              //.fromTo(self.textBox, 1, { y: +20 }, { autoAlpha: 1, y: 0 }, 'homepagecontent')
              .set('#header', { clearProps: "all" })
              ;
        } else {
          preloaderTl
              .set(self.body, { overflow: 'hidden' })
              .set(self.coverWhiteFirst, { opacity: 0 })
              .to(self.coverWhiteFirst, 0, { delay: 0.3 })
              .to(self.coverWhiteFirst, 0.8, { delay: 1.4, autoAlpha: 1 })
              .set(self.body, { className: '+=is-loaded' })
              .set(self.body, { overflow: 'visible' })
              .set(self.container, { display: 'none' })
              //.fromTo('#header', 0.6, { autoAlpha: 0, y: -20 }, { autoAlpha: 1, y: 0 })
              .to(self.coverWhiteFirst, 0.1, { autoAlpha: 0 })
              .add('homepagecontent')
              //.fromTo(self.manifesto, 1, { y: +20 }, { autoAlpha: 1, y: 0 }, 'homepagecontent')
              //.fromTo(self.textBox, 1, { y: +20 }, { autoAlpha: 1, y: 0 }, 'homepagecontent')
              //.set('#header', { clearProps: "all" })
              ;
        }


    }

    // gallerySlide: () => {
    //     new Swiper(self.homegalleryContainer, {
    //         // width: '100%',
    //         centeredSlides: true,
    //         speed: 1000,
    //         effect: 'fade',
    //         slidesPerView: 'auto',
    //         fadeEffect: {
    //             crossFade: true
    //         },
    //         loop: true,
    //         autoplay: {
    //             delay: 5000,
    //             disableOnInteraction: false
    //         },
    //     });
    // }
}
