'use strict';

import { TimelineLite, TweenLite } from 'gsap';

let self;
export default self = {
  workspace : document.getElementById('menu-item-276'),
  nav: document.querySelector('.main-nav'),
  navBox: document.querySelector('.navlist-container'),
  navlist: document.querySelectorAll('.main-nav li'),
  navlistWrapper: document.querySelectorAll('.main-nav li .list-wrapper'),
  navlistLinks : document.querySelectorAll('.main-nav li a'),
  body: document.querySelector('body'),
  header: document.querySelector('.header-inner'),
  mainHome : document.querySelector('.main-home'),
  mainWorkspace : document.querySelector('.main-workspace'),


  init : () => {

    [...self.navlistLinks].forEach( link => link.addEventListener("click", e => {
      if (window.innerWidth < 1024) {
        self.workspace.click()
      }
    }));

    self.onScroll()
    window.addEventListener('scroll', self.onScroll);
  },

  toggle : e => {
    if(!self.body.classList.contains('is-open')){
      self.openNav();
    } else {
      self.closeNav();
    }
  },

  openNav : () => {
    const tlOpen = new TimelineLite();
    tlOpen
      .set(self.body, {className: '+=is-animating'})
      .set(self.body, {className: '+=is-open'})
      .to(self.header, 0.5, {backgroundColor: 'transparent'})
      .to(self.nav, 1, {autoAlpha: 1, ease: Expo.easeOut})
      .to('.grey-box', 0.5, {autoAlpha: 1, ease: Expo.easeOut}, '-=0.8')
      .staggerTo( self.navlistWrapper, 1, {autoAlpha: 1, ease: Expo.easeOut}, 0.08, '-=0.2')
      .add('move', '-=0.8')
      // .set(self.navBox, {className: '-=is-animated'}, 'move')
      .to('.white-shape', 0.3, {autoAlpha: 1, ease: Expo.easeOut}, 'move')
      .set(self.body, {className: '-=is-animating'});
  },

  closeNav : () => {
    const tlClose = new TimelineLite();
    tlClose
      .set(self.body, {className: '+=is-animating'})
      .call(function(){
        if(document.body.classList.contains('is-down')){
          TweenLite.to(self.header, 0.1, {backgroundColor: 'white'});
        } else {
          TweenLite.to(self.header, 0.5, {backgroundColor: 'transparent'});
        }
      })
      .to([self.nav, self.navlistWrapper, '.white-shape', '.grey-box'], 2.2, {autoAlpha: 0, ease: Expo.easeOut})
      .set(self.body, {className: '-=is-open'}, '-=2')
      .set([self.nav,self.navlist,self.header], {clearProps: 'all'})
      // .set( self.navBox, {className: '+=is-animated'})
      .set(self.body, {className: '-=is-animating'});
  },

  onScroll : e => {

    let st = window.pageYOffset || document.documentElement.scrollTop;
    const navbarHeight = document.querySelector('.header-inner').clientHeight - 40;
    if (st > self.lastScrollTop && st > navbarHeight){
      self.body.classList.add('is-up');
      self.body.classList.remove('is-down');
    } else {
      if(st > navbarHeight) {
        self.body.classList.remove('is-up');
        self.body.classList.add('is-down');
      } else {
        self.body.classList.remove('is-up');
        self.body.classList.remove('is-down');
      }
    }

    // Detect bottom of page scroll
    if (self.mainHome){
      if ( (window.pageYOffset + window.innerHeight) > self.mainHome.offsetHeight ) {
          self.body.classList.add('is-at-bottom')
      } else if ( (window.pageYOffset + window.innerHeight) < self.mainHome.offsetHeight ) {
          self.body.classList.remove('is-at-bottom')
      }
    }

    // Detect bottom of page scroll
    if (self.mainWorkspace){
      if ( (window.pageYOffset + window.innerHeight) > self.mainWorkspace.offsetHeight ) {
          self.body.classList.add('is-at-bottom')
      } else if ( (window.pageYOffset + window.innerHeight) < self.mainWorkspace.offsetHeight ) {
          self.body.classList.remove('is-at-bottom')
      }
    }

    self.lastScrollTop = st;
  },

  debounce : (func, wait = 10, immediate = true) => {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
}
