'use strict'

import Swiper from 'swiper'

let self

export default self = {
  container: document.querySelector('.gallery .swiper-container'),
  collaborationContainer: null,

  init : () => {
    self.collaborationContainer = document.querySelector('.main-template .swiper-container');
    if(self.container) self.gallerySlide();
    if(self.collaborationContainer) self.collaborationSlide();
  },

  gallerySlide : () => {
    return new Swiper (self.container, {
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      autoplay: {
        delay: 436000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.progress-bar',
        type: 'progressbar',
      },
      navigation: {
        nextEl: '.btn-prev',
        prevEl: '.btn-next',
      },
    });
  },
  
  collaborationSlide : () => {
    return new Swiper (self.collaborationContainer, {
      speed: 2000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      autoplay: {
        delay: 3000,
      }
    });
  }

}