'use strict';

import {
    TimelineLite,
    TweenLite
} from 'gsap';

let self;
export default self = {
    container: document.querySelector('.floorplates'),
    images: null,
    overlayImages: null,
    body: null,
    close: null,

    init: () => {

        self.images = document.querySelectorAll('.floorplates__image');
        self.overlayImages = document.querySelectorAll('.floorplates-overlay__image img');
        self.body = document.querySelector('body');
        self.close = document.querySelector('.floorplates-overlay .btn-close');
        self.overlayCaption = document.querySelector('.floorplates-overlay__caption .caption')

        $(".views__img").cyclotron();

        Array.from(self.images).forEach((image) => {
            image.addEventListener('click', (e) => {
                const index = e.target.dataset.index;

                Array.from(self.overlayImages).forEach((image) => {
                    if (image.dataset.index == index) {
                      image.classList.add('is-clicked');
                      self.overlayCaption.innerHTML = e.target.dataset.caption
                    }
                });
                self.body.classList.add('is-showed');
            });
        });

        self.close.addEventListener('click', function (e) {
            self.body.classList.remove('is-showed');
            Array.from(self.overlayImages).forEach((image) => {
                image.classList.remove('is-clicked');
            });
        });
    }
}
