'use strict';

import "babel-polyfill"

import Preload from './modules/preload'
import Nav from './modules/nav'
import Slideshow from './modules/slideshow'
import ScrollAnimation from './modules/scrollAnimation'
import ScrollToTop from './modules/scrollToTop'
import PageTransition from './modules/pageTransition'
import SlideShow from './modules/slideshow'
import Gallery from './modules/gallery'
import Floorplan from './modules/floorplan'
import Contact from './modules/contact'

Nav.init();
SlideShow.init();
if (Preload.container) Preload.init();
if (Slideshow.collaborationContainer) Slideshow.init();
if (ScrollAnimation.container) ScrollAnimation.init();
if (ScrollToTop.container) ScrollToTop.init();
if (PageTransition.container) PageTransition.init();
if (Gallery.container) Gallery.init();
if (Floorplan.container) Floorplan.init();
if (Contact.container) Contact.init();
