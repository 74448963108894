'use strict'

import {TweenLite} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

let self

export default self = {
  container : document.querySelector('.arrow'),
  arrowUp : document.querySelector('.arrow--up'),
  topOfNav : 100,

    init : () => {
        window.addEventListener('scroll', self.debounce(self.showArrow));
        self.arrowUp.addEventListener('click', self.scrollTo);
    },

    showArrow : () => {
        if(window.pageYOffset >= self.topOfNav ){
            self.container.classList.add('is-show');
        } else {
            self.container.classList.remove('is-show');
        }
    },

    scrollTo : (e) => {
        e.preventDefault();
        TweenLite.to(window, 1, {scrollTo: 'main'});
    },

    debounce : (func, wait = 10, immediate = true) => {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
}