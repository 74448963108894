'use strict'

let self

export default self = {
  container: document.getElementById('gallery'),
  slideIndex: 0,

  init: () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements: () => {
    self.container = document.querySelector('#gallery')
    self.images = self.container.querySelectorAll('article figure')
    self.overlay = document.getElementById('galleryOverlay')
    self.slides = self.overlay.querySelectorAll('.slide')
    self.btnClose = self.overlay.querySelector('.btn-close')
    self.btnPrev = self.overlay.querySelector('.btn-prev')
    self.btnNext = self.overlay.querySelector('.btn-next')
    self.copy = self.container.querySelector('.copy')
  },

  registerEvents: () => {
    Array.from(self.images).forEach(image => image.addEventListener("click", self.toggleOverlay))
    self.btnClose.addEventListener("click", self.toggleOverlay)
    self.btnPrev.addEventListener("click", self.changeSlide)
    self.btnNext.addEventListener("click", self.changeSlide)
  },

  toggleOverlay: e => {

    // Hide the overlay and kill execution of the rest of this function
    if (self.overlay.classList.contains('show')) {
      self.overlay.classList.remove('show')
      self.overlay.querySelector('.slide.show').classList.remove("show")
      return false
    }

    // Show the overlay
    self.overlay.classList.add('show')

    //
    let selectedThumb = e.currentTarget
    self.slideIndex = Number(selectedThumb.getAttribute("data-index"))

    self.slides[self.slideIndex].classList.add('show')

  },

  changeSlide: e => {
    let dir = e.currentTarget.className.replace("btn-", "")

    self.setSlideVisibility("remove")
    self.slideIndex = self.getNewSlideIndex(dir)
    self.setSlideVisibility("add")
  },

  setSlideVisibility: fn => self.slides[self.slideIndex].classList[fn]("show"),

  getNewSlideIndex: action => {
    let index

    switch (action) {
      case 'prev':
        index = (self.slides[self.slideIndex - 1]) ? self.slideIndex - 1 : self.slides.length - 1
        break;
      case 'next':
        index = (self.slides[self.slideIndex + 1]) ? self.slideIndex + 1 : 0
        break;
    }

    return index
  }
}
