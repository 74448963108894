'use strict'

let self

export default self = {
  init : (page) => {
    document.body.classList.remove('is-at-bottom')
    window.removeEventListener("scroll", self.onScroll)
    self.mainWorkspace, self.mainHome = null

    switch(page) {
      case 'workspace':
        self.mainWorkspace = document.querySelector('.main-workspace')
      default:
        self.mainHome = document.querySelector('.main-home')
    }

    window.addEventListener("scroll", self.onScroll)
  },

  onScroll : () => {

    // Detect bottom of page scroll
    if (self.mainHome){
      if ( (window.pageYOffset + window.innerHeight) > self.mainHome.offsetHeight ) {
          document.body.classList.add('is-at-bottom')
      } else if ( (window.pageYOffset + window.innerHeight) < self.mainHome.offsetHeight ) {
          document.body.classList.remove('is-at-bottom')
      }
    }

    // Detect bottom of page scroll
    if (self.mainWorkspace){
      if ( (window.pageYOffset + window.innerHeight) > self.mainWorkspace.offsetHeight ) {
          document.body.classList.add('is-at-bottom')
      } else if ( (window.pageYOffset + window.innerHeight) < self.mainWorkspace.offsetHeight ) {
          document.body.classList.remove('is-at-bottom')
      }
    }

  }
}
